<template>
	<div class="button-container" :class ="restrictedNeedsMoreInfo&&
							fromPlayerCashCOC  >=  webPayOptions.taxAmlRestrictions.buyInThresholdCOC  ? 'disable-container' : ''">
		<button class="btn buy-in-btn curved-border" :disabled="restrictedNeedsMoreInfo &&
							fromPlayerCashCOC  >=  webPayOptions.taxAmlRestrictions.buyInThresholdCOC" :id="`commit-offer${buyInOffer.id}`" @click="order()">
			{{ languageStrings.commitTransaction }}
		</button>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import { onBeforeUnmount } from "vue";
import router from "@/router";

export default {
	//Conekta was bought out and is now called DigitalFemsa
	//behind the scenes we are still calling it Conekta for requests
	//but it is actually DigitalFemsa that we are working with
	name: "Conekta",
	props: {
		playerState: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		webPayOptions: Object,
		isMobile: Boolean,
		buyInOffer: Object,
		fromPlayerCashCOC: Number,
		restrictedNeedsMoreInfo: Boolean,
		provider: String,
		paymentMethodCode: String
	},
	components: {},
	data() {
		return {
			activeSession: this.session.get(),
			status: Object.assign({}, this.globalStatus),
			currencyInfo: this.playerState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			minorOrFull: this.systemCurrencyTool.displayType.minorOrFull,
			provider: "",
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		async order() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.initiatingBuyIn;

			let hasErrors = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (!this.fromPlayerCashCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterBuyInAmount;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (isNaN(this.fromPlayerCashCOC)) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterNumberOnly;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.fromPlayerCashCOC < this.webPayOptions.conekta.buyInMinimumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustMeetMinimumBuyIn;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.fromPlayerCashCOC > this.buyInOffer.maximumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.cannotExceedMaximumBuyIn;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.provider === "conekta" && this.fromPlayerCashCOC < 10) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `$10 ${this.languageErrorStrings.minimumForBuyIn}`;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (
				this.fromPlayerCashCOC > this.webPayOptions.taxAmlRestrictions.buyInThresholdCOC &&
				this.webPayOptions.taxAmlRestrictions.idCheckNeeded
			) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `${this.systemCurrencyTool.formatCurrency(
					this.webPayOptions.taxAmlRestrictions.buyInThresholdCOC,
					this.systemCurrencyTool.displayType.full
				)} ${
					this.languageErrorStrings
						.completeNeoCheckIdVerification
				}.`;
				this.eventBus.emit("updateStatus", this.status);
				router.push("/identificationStatus");
				return false;
			}

			if (hasErrors === true) return;
			//DigitialFemsa is the the company that bought out Conekta, but all of our end still calls it by Conekta
			//For now all of the code stays the same when it comes to the request so far
			let body = {
				casinoId: this.webPayOptions.casino.id,
				buyInOfferId: this.buyInOffer.id,
				fromPlayerCashCOC: this.fromPlayerCashCOC,
				provider: "Conekta",
			};
			let requestUrl = new URL("/api/v1/webpay/buyin/initiate", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);
				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				this.transaction = await response.json();

				if (this.transaction?.status === "EmailNotVerified") {
					console.error(this.transaction?.status);
					this.status.ok = false;
					this.status.message =
						this.languageErrorStrings.emailNotVerifiedPleaseGoToYourUserProfileAndVerifyYourEmail;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(this.transaction.status, this.languageErrorStrings);
					this.eventBus.emit("updateStatus", this.status);

				if (this.status.ok) {
					this.performConektaCheckout();
					this.status.message = this.languageErrorStrings.webPayInitiated;
					this.eventBus.emit("updateStatus", this.status);
				}
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		performConektaCheckout() {
			// Options copied from example code.
			const options = {
				backgroundMode: "lightMode", //lightMode o darkMode
				colorPrimary: "#081133", //botones y bordes
				colorText: "#585987", // títulos
				colorLabel: "#585987", // input labels
				inputType: "minimalMode", // minimalMode o flatMode
			};
			
			// this is not actually conekta but digitalFemsa, but we are keeping the naming the same
			const config = {
				locale: "en",
				// locale: this.countryCode === "MX" ? "es" : "en",
				publicKey: this.webPayOptions.conekta.apiKeyPublic,
				targetIFrame: "#conekta-checkout-element",
				checkoutRequestId: this.transaction.sessionId,
			};
			const callbacks = {
				onGetInfoSuccess: this.handleGetInfo,
				onFinalizePayment: this.handleFinalizePayment,
				onErrorPayment: this.handlePaymentError,
			};
			window.DigitalFemsaCheckoutComponents.Integration({
				config,
				callbacks,
				options,
			});
		},
		handleGetInfo(loadingTime) {
			this.eventBus.emit("showConektaWidgit", true);
		},
		async handleFinalizePayment(order) {
			await updateRfTranSatus("WaitingForProviderConfirmation");
			// this.eventBus.emit("showConektaWidgit", false);
			this.status.ok = true;
			this.status.message = this.languageErrorStrings.success;
			this.eventBus.emit("updateStatus", this.status);
			this.eventBus.emit("conektaCheckoutResult", order);
		},
		handlePaymentError(error) {
			this.status.ok = false;
			this.status.message = error;
			this.eventBus.emit("updateStatus", this.status);
			// this.eventBus.emit("showConektaWidgit", false);
		},
		async updateRfTranSatus(newStatus) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				webTranId: this.transaction.transaction.id,
				newStatus: newStatus,
			};

			let requestUrl = new URL("/api/v1/webpay/transaction/update", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let rfRequestStatus = await response.json();

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(this.transaction.status, this.languageErrorStrings);

			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.buy-in-btn {
	width: 100%;
	height: 100%;
	margin: 0;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}

.disable-container {
	opacity: 30%;
}
</style>
